<template>
    <div class="j_content step3 update">
        <div class="info clear">
            <div class="title-wrap">
                <div class="title">정보입력</div>
            </div>
            <div class="form">
                <div class="form-data file">
                    <div class="title">프로필<br>이미지</div>
                    <div class="value-wrap">
                        <el-upload
                                class="avatar-uploader border-radius-100 size88"
                                action=""
                                list-type="picture-card"
                                :show-file-list="false"
                                :file-list="files"
                                :auto-upload="false"
                                :on-change="onlyOnePhotoChange"
                        >
                            <label :class="{'no-image' : !hasImage()}"> <!--파일이없다면 class="no-image" 추가 -->
                                <img v-if="hasImage()" :src="returnProfileImage()">

                                <!--<img src="@/assets/image/rebuild/popup_img.jpg">--> <!--파일이 있다면 img태그로 이미지 여기에-->
                            </label>
                        </el-upload>
                    </div>
                </div>
                <div class="form-data disabled">
                    <div class="title">아이디</div>
                    <div class="input">
                        {{id}}
                    </div>
                </div>
                <div class="form-data disabled">
                    <div class="title">이름</div>
                    <div class="input">
                        {{name}}
                    </div>
                </div>
                <div class="form-data disabled">
                    <div class="title">생년월일</div>
                    <div class="input">
                        {{returnBirthFormat(birth)}}
                    </div>
                </div>
                <div class="form-data email">
                    <div class="title">이메일</div>
                    <div class="input">
                        <input type="text" class="value" v-model="email" @input="parentSetData('email', email)" maxLength="30"></input>
<!--                        <input type="text" class="value" placeholder="이메일입력"></input>-->
                    </div>
                </div>
                <div class="form-data">
                    <div class="title">비밀번호</div>
                    <div class="input check">
                        <input type="password" class="value" disabled="disabled" value="***********"></input>
                        <button class="send" @click="openModal('update-pw-modal')">수정</button>
                    </div>
                </div>
                <div class="form-data">
                    <div class="title">전화번호</div>
                    <div class="input check">
                        <input type="text" class="value" disabled="disabled" :value="phone"></input>
                        <button class="send" @click="openModal('update-phone-modal')">수정</button>
                    </div>
                </div>
                <div class="form-data">
                    <div class="title">자기소개</div>
                    <div class="introduce">
                        <textarea :placeholder="introduce" v-model="introduce" @input="parentSetData('introduce', introduce)" style="resize: none;">

                        </textarea>
                    </div>
                </div>
            </div>
            <div class="form-check">
                <button class="full_btn register" @click="checkValidation()">저장</button>
                <div class="form-caption" v-show="hasError">모두 입력해주세요</div>

            </div>
            <button class="leave-btn" @click="$router.push('/quit')">회원탈퇴</button>
        </div>
    </div>
</template>

<script>
    // @ is an alias to /src
    import MemberValidator from "@/mixins/validators/MemberValidator";
    import {mapState, mapGetters} from "vuex";
    import util from "@/mixins/util";
    import elFileMixins from "@/mixins/elFileMixins";

    export default {
        name: 'MemberUpdateMenteeLayout',
        mixins: [MemberValidator,elFileMixins],
        inject: ['memberUpdateSetData', 'memberUpdateFunc'],
        components: {},
        props: {},
        data() {
            return{
                value1: false,
                hasError: false,

            }
        },
        beforeRouterEnter() {},
        created() {
        },
        mounted() {
            this.setDefault();
            this.$store.subscribe((mutation, state) => {
                switch (mutation.type) {
                    case 'userInfo/updateUserInfo':
                        this.setDefault();
                }
            });
        },
        beforeDestroy() {
        },
        destroyed() {},
        computed: {
            ...mapState({
                UserInfo: state => state.userInfo.UserInfo,
            })},
        methods:{
            openMemberModal() {},
            setDefault() {
                this.parentSetData('job',this.UserInfo.mb_job)
                this.parentSetData('belong',this.UserInfo.mb_belong)
                this.parentSetData('name',this.UserInfo.mb_name)
                this.parentSetData('birth',this.UserInfo.mb_birth)
                this.parentSetData('id',this.UserInfo.mb_id)
                this.parentSetData('no',this.UserInfo.mb_no)
                this.parentSetData('email',this.UserInfo.mb_email)
                this.parentSetData('phone',this.UserInfo.mb_hp)
                this.parentSetData('introduce',this.UserInfo.mb_memo)
                this.parentSetData('pushAlarm',this.UserInfo.mb_alarm)
                // this.parentSetData('alarmJob',this.UserInfo.memberJob)
//                this.parentSetData('files',this.UserInfo.Profile)
                if(!util.isEmpty(this.UserInfo.Profile)) {
                    this.files = [this.UserInfo.Profile[0]];
                    this.files[0].url = this.files[0].org_url;
                }
            },
            returnBirthFormat(date) {
                if(!date) {
                    return '';
                }
                return this.$moment(date).format('YYYY-MM-DD')
            },
             returnProfileImage() {
                return !util.isEmpty(this.files) && this.files.length > 0 ? `${this.files[0].url}` : ''
            },
            openModal(name) {
                this.$modal.show(name);
            },

            parentSetData(type, value) {
                this[type] = value;
                this.memberUpdateSetData(type, value)
                this.hasError = false;
            },
            checkValidation() {
                let validatorCheck = ["files", "email", "introduce"]
                this.$validate(validatorCheck).then(success => {
                    if (!success) {
                        this.hasError = true;
                        return false;
                    }
                    this.memberUpdateFunc();
                })
            },
            toggleStatus(value) {
                if (value) {
                    this.parentSetData('pushAlarm', 1)
                } else {
                    this.parentSetData('pushAlarm', 0)
                }
            },
            hasImage() {
                return !util.isEmpty(this.files);
            },
        },
        watch: {

        },
    }
</script>
